

















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import { getDicts } from '@/utils/dict'
// import Dialog from '@/components/Dialog/index.vue'
import {
  Select,
  Option,
  DatePicker,
  Checkbox,
  Upload,
  Autocomplete,
  Dialog,
  CheckboxGroup
} from 'element-ui'
Vue.use(Select)
Vue.use(Option)
Vue.use(DatePicker)
Vue.use(Checkbox)
Vue.use(Upload)
Vue.use(Autocomplete)
Vue.use(Dialog)
Vue.use(CheckboxGroup)

@Component
export default class extends Vue {
  // 字典数据
  // 区域
  areaDicts: any = []
  // 输入建议
  // 房屋
  houseDevice: any = []
  cardInfo = {
    houseCardNo: '',
    phase: '',
    machineCardNo: '',
    remark: '',
    buildingNo: '',
    houseId: ''
  }
  cardInfoRules = {
    machineCardNo: [
      { required: true, message: '请输入读卡器卡号', trigger: 'blur' },
      { max: 50, message: '长度在50个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9]+$/,
        message: '请输入数字',
        trigger: 'blur'
      }
    ],
    remark: [{ max: 200, message: '长度在200个字符', trigger: 'blur' }],
    houseCardNo: [
      { required: true, trigger: 'blur', message: '请转换读卡器获取卡号' }
    ],
    phase: [{ required: true, message: '请选择区域', trigger: 'blur' }],
    buildingNo: [{ required: true, message: '请输入房屋号', trigger: 'blur' }]
  }
  loading = false
  // 输入建议
  restaurants: any = []
  $constants: any
  infoLoading = false
  // 编辑
  editCardId: any = ''

  async beforeCreate() {
    // 获取相关字典
    // 区域
    this.areaDicts = await getDicts('PHASE')
  }

  async mounted() {
    this.editCardId = ''
    this.houseDevice = await this.loadAllHouse()
    if (this.$route.query.id) {
      this.editCardId = this.$route.query.id
      this.getCardKeyInfo()
    }
  }

  getCardKeyInfo() {
    this.$api.card.getCardKeyInfo(this.editCardId).then((res: any) => {
      if (res.data.code === '200' && res.data.success) {
        const data = res.data.data
        this.cardInfo = {
          houseCardNo: data.houseCardNo,
          phase: data.phase,
          machineCardNo: data.machineCardNo,
          remark: data.remark,
          buildingNo: data.buildingNo,
          houseId: data.houseId
        }
      }
    })
  }

  // 转换
  convert() {
    if (this.cardInfo.machineCardNo === '') {
      return this.$message.warning('请输入读卡器卡号')
    }
    if (!/^[0-9]+$/.test(this.cardInfo.machineCardNo)) {
      return this.$message.warning('请输入数字')
    }
    this.$api.card
      .convertCard({
        machineCardNo: this.cardInfo.machineCardNo
      })
      .then((res: any) => {
        if (res.data.success && res.data.code === '200') {
          this.cardInfo.houseCardNo = res.data.data
        }
      })
  }

  getHouse() {
    this.cardInfo.buildingNo = ''
    this.cardInfo.houseId = ''
    this.houseDevice = this.loadAllHouse(this.cardInfo.phase)
  }

  handleSelect(val: any) {
    const temp = this.houseDevice.filter((item: any) => item.value === val)
    if (temp && temp.length > 0) {
      this.cardInfo.houseId = temp[0].id
    }
  }

  // 获取所有房屋
  loadAllHouse(phase?: string) {
    const sourceData: any = []
    this.$api.resident.getHouseList({ phase, size: -1 }).then((res: any) => {
      const data = res.data
      if (data.success) {
        data.data.map((v: any) => {
          sourceData.push({
            id: v.id,
            value: v.buildingNo,
            owner: v.ownerName
          })
        })
      }
    })
    return sourceData
  }

  addSave() {
    if (this.editCardId) {
      // 编辑
      ;(this.$refs['cardInfo'] as any).validate((valid: any) => {
        if (valid) {
          this.loading = true
          this.$api.card
            .addEditCardKey({
              ...this.cardInfo,
              id: this.editCardId
            })
            .then((res: any) => {
              this.loading = false
              if (res.data.code === '200' && res.data.success) {
                this.$message.success('编辑门禁卡成功')
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    } else {
      // 新增
      ;(this.$refs['cardInfo'] as any).validate((valid: any) => {
        if (valid) {
          this.loading = true
          this.$api.card
            .addEditCardKey(this.cardInfo)
            .then((res: any) => {
              this.loading = false
              if (res.data.code === '200' && res.data.success) {
                this.$message.success('新增门禁卡成功')
                this.goback()
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    }
  }

  // 返回
  goback() {
    this.$router.push('/card-key/list')
  }
}
